export default {
  select_prompt: 'Select or create',
  search_prompt: 'Company Name',
  about: 'About company',
  show: 'Open Profile',
  add_company_prompt: 'Add new one',
  company_location: 'Company location',
  company_name: 'Company Name',
  company_primary_business: 'Company primary business',
  company_site: 'Company site',
  enter_company_data: 'Enter company data or',
  employees: 'Employees',
  employees_title: 'Employees',
  employees_size: 'Employees',
  primary_business: 'Primary Business',
  name: 'Name',
  tags: 'Tags',
  logo: 'Logo',
  hero: 'Profile Hero',
  hero_size: 'Size',
  location: 'Location',
  site: 'Site',
  empty_error_text: 'Select at least one value',
  organizer: 'Organizer',
  select_company_from_list: 'select company from the list',
  sponsor_platinum: 'Platinum Sponsor',
  sponsor_gold: 'Gold Sponsor',
  sponsor_silver: 'Silver Sponsor',
  sponsor_bronze: 'Bronze Sponsor',
  unable_to_find_company_question: 'Can\'t find company?',
  games: 'Games',
  wn_manager: 'Responsible manager',
  no_wn_manager: 'No responsible manager',
  no_company: 'No company'
}
