import { Type, Expose, Transform } from 'class-transformer'
import { BaseModel } from './baseModel'
import { User, UserLite } from './user'
import { Ticket } from './ticket'

export enum MemberRole {
  Member = 1,
  Moderator,
  Helper,
  CourseAdmin,
  CourseTeacher,
  CourseStudent,
  Sponsor,
  Speaker,
  Developer,
}

export class MemberSettings {
  @Expose({ name: 'enable_homework_teacher_notification' })
  enableHomeworkNotification!: boolean
}

export class MemberLite extends BaseModel {
  uid!: string

  @Expose({ name: 'ticket_type' })
  ticketType!: number

  // in member list for yourself it's always false
  // in members/me for yourself it's absent
  @Expose({ name: 'can_chat' })
  canChat?: boolean

  // in member list for yourself it's always false
  // in members/me for yourself it's absent
  @Expose({ name: 'can_offline_meeting' })
  canOfflineMeeting?: boolean

  @Expose({ name: 'is_sponsor' })
  isSponsor!: boolean

  @Expose({ name: 'is_speaker' })
  isSpeaker!: boolean

  @Expose({ name: 'is_business' })
  isBusiness!: boolean

  @Expose({ name: 'is_organizer' })
  isOrganizer!: boolean

  @Type(() => UserLite)
  user!: UserLite

  get company() { return this.user.company }
}
export class Member extends MemberLite {
  @Expose({ name: 'can_meeting' })
  canMeeting!: boolean

  @Expose({ name: 'can_upgrade' })
  canUpgrade!: boolean

  @Expose({ name: 'can_vote' })
  canVote!: boolean

  @Expose({ name: 'role_id' })
  roleId!: MemberRole

  @Expose({ name: 'role_ids' })
  @Transform(({ value }) => !value ? [] : value)
  roleIds!: MemberRole[]

  @Expose({ name: 'employee_roles' })
  employeeRoles!: string[]

  @Type(() => User)
  user!: User

  @Type(() => Ticket)
  ticket!: Ticket

  @Transform(({ value }) => value || {}, { toClassOnly: true })
  @Type(() => MemberSettings)
  settings!: MemberSettings

  get isHasCompanyChatRole() {
    return this.employeeRoles?.includes('chat')
  }

  get isModerator() {
    return this.roleId === MemberRole.Moderator || this.roleIds.includes(MemberRole.Moderator)
  }

  get isHelper() {
    return this.roleId === MemberRole.Helper || this.roleIds.includes(MemberRole.Helper)
  }

  get isCourseAdmin() {
    return this.roleId === MemberRole.CourseAdmin || this.roleIds.includes(MemberRole.CourseAdmin)
  }

  get isTeacher() {
    return this.roleId === MemberRole.CourseTeacher || this.roleIds.includes(MemberRole.CourseTeacher)
  }

  get isStudent() {
    return this.roleId === MemberRole.CourseStudent || this.roleIds.includes(MemberRole.CourseStudent)
  }

  get isOfflineMember() {
    return this.ticket.features.some(feature => feature.feature === 'offline')
  }
}
